<template>
  <div class="add-trip-vehicle-sidebar">
    <div class="add-trip-vehicle-sidebar--content">
      <v-form ref="form">
        <CRInput
          id="trip-vehicle-group-name-input"
          v-model="tripVehicleGroup.manuallySetName"
          :placeholder="tripVehicleGroup.defaultName"
          label="Group Name"
        />
        <div class="trip-vehicle-group-name-sidebar--content">
          <label>Group Color</label>
          <v-radio-group
            v-model="tripVehicleGroup.color"
            row
            mandatory
            :right="0"
          >
            <v-radio
              v-for="color in colors"
              :key="color.hex"
              :label="color.name"
              :color="color.hex"
              :value="color.hex"
            ></v-radio>
          </v-radio-group>
        </div>
        <div
          v-for="(vehicle, vehicleIndex) in tripVehicleGroup.tripVehicles"
          :key="`add-trip-vehicle-row-${vehicleIndex}`"
        >
          <v-layout padded>
            <v-flex xs6>
              <CRSelect
                :id="`add-trip-vehicle-vehicle-type-selector-${vehicleIndex}`"
                v-model.number="vehicle.vehicleTypeId"
                :items="vehicleTypes"
                item-value="id"
                item-text="label"
                label="Vehicle Type"
                placeholder="e.g. Charter Bus, Limousine, etc"
                browser-autocomplete="off"
              />
            </v-flex>
            <v-flex xs1 />
            <v-flex xs4>
              <CRInput
                :id="`add-trip-vehicle-quantity-${vehicleIndex}`"
                v-model.number="vehicle.quantity"
                label="Vehicles"
                type="number"
                min="1"
                placeholder="e.g. 1"
                tabindex="0"
                browser-autocomplete="off"
                style="margin-left: 4px"
              />
            </v-flex>
            <v-flex xs1>
              <v-btn
                v-if="tripVehicleGroup.tripVehicles.length > 1"
                :id="`add-trip-vehicle-remove-button-${vehicleIndex}`"
                icon
                style="margin-top: 26px !important; margin-left: 4px"
                @click="removeVehicle(vehicleIndex)"
              >
                <CRIcon class="delete-stop-icon">trash</CRIcon>
              </v-btn>
            </v-flex>
            <div>&nbsp;</div>
          </v-layout>
        </div>
        <p>
          <a
            :id="`add-trip-vehicle-add-button`"
            class="vehicle-add-button"
            @click="addVehicle"
          >
            + Add Another Vehicle Type
          </a>
        </p>
      </v-form>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="add-trip-vehicle-save-btn"
        :loading="submitting"
        class="add-trip-vehicle-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Save
      </CRButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { TripVehicle } from '@/classes/TripVehicle'
import { TripVehicleGroup } from '@/classes/TripVehicleGroup'
import themeColors from '@/scss/_colors-export.scss'

const CRColors = {}
for (const [key, value] of Object.entries(themeColors)) {
  CRColors[key] = value
}

export default {
  props: {
    mode: {
      type: String,
      default: 'add',
    },
    tripVehicleGroupIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tripVehicleGroup: new TripVehicleGroup({}),
      colors: [
        { name: 'purple', hex: CRColors['shuttle-purple'] },
        { name: 'yellow', hex: CRColors['shuttle-yellow'] },
        { name: 'green', hex: CRColors['shuttle-green'] },
        { name: 'blue', hex: CRColors['shuttle-blue'] },
        { name: 'red', hex: CRColors['shuttle-red'] },
        { name: 'pink', hex: CRColors['shuttle-pink'] },
        { name: 'lime', hex: CRColors['shuttle-brat'] },
      ],
    }
  },
  computed: {
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    vehicleTypes() {
      return this.$store.getters['types/vehicleTypes']
    },
    trip() {
      return this.$store.getters['quoteForm/getCurrentTrip']
    },
    currentTripIndex() {
      return this.$store.getters['quoteForm/getCurrentTripIndex']
    },
    currentTripVehicleGroup() {
      return this.$store.getters['quoteForm/getCurrentTripVehicleGroup']
    },
  },
  async mounted() {
    if (!this.vehicleTypes || !this.vehicleTypes.length) {
      await this.setVehicleTypesModule()
    }
    if (this.isModeEdit) {
      this.tripVehicleGroup = new TripVehicleGroup({
        ...this.currentTripVehicleGroup.toObject(),
      })
    }
    // seed with new trip vehicle if none are present
    if (this.tripVehicleGroup.tripVehicles.length === 0) {
      this.tripVehicleGroup.tripVehicles.push(new TripVehicle({}))
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      setVehicleTypesModule: 'types/setVehicleTypes',
      addTripVehicleGroup: 'quoteForm/addTripVehicleGroup',
      setTripVehicleGroup: 'quoteForm/setTripVehicleGroup',
    }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    addVehicle() {
      this.tripVehicleGroup.tripVehicles.push(new TripVehicle({}))
    },
    removeVehicle(vehicleIndex) {
      if (this.tripVehicleGroup.tripVehicles.length > 1) {
        this.tripVehicleGroup.tripVehicles.splice(vehicleIndex, 1)
      }
    },
    async submit() {
      const missingVehicleInfo = this.tripVehicleGroup.tripVehicles.some(
        (vehicle) => vehicle.vehicleTypeId === null || vehicle.quantity < 1
      )
      if (missingVehicleInfo) {
        this.showAlert({
          type: 'error',
          message: 'Must input vehicle information.',
        })
        return
      }
      if (this.isModeAdd) {
        this.addTripVehicleGroup({
          tripIndex: this.currentTripIndex,
          tripVehicleGroup: this.tripVehicleGroup,
        })
      } else if (this.isModeEdit) {
        this.setTripVehicleGroup({
          tripIndex: this.currentTripIndex,
          tripVehicleGroup: this.tripVehicleGroup,
          tripVehicleGroupIndex: this.tripVehicleGroupIndex,
        })
      }
      this.showAlert({
        type: 'success',
        message: `Vehicles ${this.isModeAdd ? 'Added' : 'Updated'}.`,
      })
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.add-trip-vehicle-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}
</style>
